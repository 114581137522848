@import "./font";
@import "./campminderIntegrationPortal";
@import "./sharpspringIntegrationPortal";

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  font-family: "Poppins", sans-serif;
}

#root {
  height: 100%;
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

.err {
  margin-top: 10px;
  color: #fd7062;
  font-size: 14px;
  font-weight: 600;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .spinner {
    height: 56px;
    width: 56px;
    box-sizing: border-box;
    background: conic-gradient(
      from 90deg at 50% 50%,
      rgba(39, 174, 96, 0) 0deg,
      rgba(31, 144, 255, 0) 0.04deg,
      #1f90ff 360deg
    );
    border-radius: 56px;
    animation: 1s rotate infinite linear;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 40px;
      width: 40px;
      background: #3e3d40;
      border-radius: 48px;
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 8px;
      width: 8px;
      background: #1f90ff;
      border-radius: 8px;
    }
    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .text {
    color: #c8cad2;
    font-size: 16px;
    font-weight: 600;
    margin-top: 5px;
  }
}
